import { CustomerShort } from "./customers";
import { SidebarPrefix } from "~/types/general";
import { StoreShort } from "./stores";

export interface TransportUnit {
  id: number;
  amount: number;
  note: string;
  type: TransportUnitType;
  pallet_type: PalletType;
  customer: CustomerShort;
  store: StoreShort;
  created_at: string;
}

export enum TransportUnitStatus {
  OUTGOING = "outgoing",
  INCOMING = "incoming",
}

export enum TransportUnitType {
  CLOSED = "closed",
  OUTSTANDING = "outstanding",
  ACTIVE = "active",
}

export enum PalletType {
  CHEP = "chep",
  LOSCAM = "loscam",
  PLAIN = "plain",
}

export const SidebarPage = {
  TRANSPORT_UNITS_FILTERS: `${SidebarPrefix.TRANSPORT_UNITS}-filters`,
  TRANSPORT_UNITS_NEW_LOG: `${SidebarPrefix.TRANSPORT_UNITS}-new-log`,
  TRANSPORT_UNITS_CONFIGURATION: `${SidebarPrefix.TRANSPORT_UNITS}-configuration`,
};
